import React, { Component } from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import HtmlWrapper from "../components/HtmlWrapper";
import Container from "../components/Container";
import Link from "gatsby-link";
import { GatsbyImage } from "gatsby-plugin-image";
import { createLocalLink } from "../utils/index";

const LeadSpacing = styled.div`
  ${tw`mt-32 sm:mt-48`}
`;

const ArticleWrapper = styled.article`
  ${tw`mb-8 sm:mb-24`}
`;

const LinkWrapper = styled((props) => <Link {...props} />)`
  ${tw`flex flex-col sm:flex-row`}
`;

const FeaturedImage = styled((props) => <GatsbyImage {...props} />)`
  ${tw`w-full sm:w-7/12`}
`;

const Content = styled.header`
  ${tw`flex w-full sm:w-5/12 items-center px-0 sm:px-16`}
`;

const Title = styled.h2`
  ${tw`text-4xl sm:text-6xl font-semibold`}
`;

export default class Nyheder extends Component {
  render() {
    const { data } = this.props;
    const PageData = {
      seo: {
        title:
          "Nyheder – De seneste nyheder om events og tips til festen – DJ Booking",
        focuskw: "Nyheder",
        metaDesc:
          "Hold dig opdateret med de seneste nyheder fra artister og events over hele landet. Vi udbyder også tips og tricks til hvordan du finder den perfekt artist.",
      },
    };

    return (
      <HtmlWrapper seo={PageData}>
        <LeadSpacing />
        <Container>
          {data.wp.posts.edges.map(({ node }) => (
            <ArticleWrapper key={node.id}>
              <LinkWrapper to={createLocalLink(node.link)}>
                {node.featuredImage && (
                  <FeaturedImage
                    image={
                      node.featuredImage.imageFile.childImageSharp.gatsbyImageData
                    }
                    alt={node.featuredImage.altText}
                  />
                )}
                <Content>
                  <Title>{node.title}</Title>
                </Content>
              </LinkWrapper>
            </ArticleWrapper>
          ))}
        </Container>
      </HtmlWrapper>
    );
  }
}

export const query = graphql`query Articles {
  wp {
    posts(first: 200, after: null) {
      edges {
        node {
          id
          title
          uri
          status
          link
          content
          excerpt
          featuredImage {
            sourceUrl
            altText
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  width: 900
                  height: 600
                  quality: 100
                  transformOptions: {cropFocus: CENTER, grayscale: true}
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  }
}
`;
